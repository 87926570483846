<template>
  <ChipsButton
    :title="formatDisplayValue(displayValue)"
    :aria-label="$t('auction-list.remove')"
  >
    <span
      :class="{
        'block first-letter:uppercase':
          groupKey !== 'descriptionTitles' && groupKey !== 'designations'
      }"
    >
      {{ formatDisplayValue(displayValue) }}
    </span>
  </ChipsButton>
</template>
<script setup lang="ts">
import type { Ref } from 'vue'
import type { useAuctionStore } from '@autobid/nuxt-pinia-store/store/useAuctionStore'
import type { ParsedFilterData } from '@autobid/strapi-integration/typescript/FilterData'
import ChipsButton from '@autobid/ui/components/common/Chips/ChipsButton.vue'
export type GroupKey = keyof ReturnType<typeof useAuctionStore>['filter']

interface Props {
  groupKey: GroupKey
  value: string | number | { from: string; to: string }
}

const props = defineProps<Props>()

const filterData = inject('filterData') as Ref<ParsedFilterData>

const { t } = useI18n()

const displayValue = computed(() => {
  switch (props.groupKey) {
    case 'appIds': {
      return filterData.value.apps.find((item) => item.id === props.value).name
    }
    case 'countryIds': {
      return filterData.value.countries.find(
        (country) => country.id === props.value
      ).name
    }
    default: {
      return props.value
    }
  }
})

const formatDisplayValue = (value: unknown) => {
  const translation = t(
    `auction-list.${value.toString().replace(/_/g, '-').toLowerCase()}`
  )
  const isTranslation = !translation.includes('auction-list.')

  if (isTranslation) {
    return translation
  }

  return value.toString().replace(/_/g, ' ')
}
</script>
